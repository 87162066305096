@charset "UTF-8";
/* CSS Document */


/*********** Global ***********/

html {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
}

input:focus {
	outline: none;
}

body {
	background: #fff;
	font-family: $body-font-family;
	font-size: 14px;
	color: #000;
}

p, ol, ul {
	line-height: 20px;
	margin: 0 0 30px;

	ol, ul {
		margin: 0 0 30px;
	}
}

a, a:visited {
	color: $color-blue;
	text-decoration: none;
	transition: all 0.1s linear;
	font-weight: normal;
}

a:hover, a:active {
	color: #1681a3;
	text-decoration: none;
}

h1 {
	font-family: $heading-font-family;
	font-size: 60px;
	line-height: normal;
	margin: 0 0 20px 0;

}

h2 {
	font-family: $heading-font-family;
	font-size: 24px;
	line-height: 36px;
	margin: 0 0 20px 0;
}

h3 {
	font-family: $heading-font-family;
	font-size: 18px;
	line-height: 24px;
	margin-bottom: 20px;
}

h4 {
	font-family: $heading-font-family;
	font-size: 14px;
	font-weight: 700;
	margin-bottom: 20px;
}

h5 {
	font-size: 12px;
}

h6 {
	font-size: 10px;
}

a.dark-btn {
	border-radius: 3px;
	background: $color-faded-blue;
	color: #fff;
	padding: 10px 30px;
}

a.dark-btn:hover {
	background: #576493;
	color: #fff !important;
}

a.light-btn {
	border-radius: 3px;
	background: $color-blue;
	color: #fff;
	padding: 10px 30px;
}

a.light-btn:hover {
	background: #25bbeb;
	color: #fff !important;
}

.gray {
	color: $color-med-gray;
}



/***** Header/Nav Styles *****/


.header {
	background: $color-blue-gray;
	margin: 30px 0 40px 0;
	border-bottom: 1px solid $color-table-blue;
	border-top: 1px solid $color-table-blue;
}

.home-brand {
	margin-top: 40px;
}

.brand {
	margin: -27px 0 -22px -15px;
}

::-webkit-input-placeholder {
   color: #222;
}

:-moz-placeholder { /* Firefox 18- */
   color: #222;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: #222;
}

:-ms-input-placeholder {
   color: #222;
}




/***** Hero *****/


.hero-wrapper {
	background: url('../img/home-bg.jpg') no-repeat;
	background-size: cover;
	width: 100%;
	height: 700px;

	.brand {
		margin-top: 40px;
	}

	.nav-aux,
	.nav {
		margin-top: 60px;

		ul {
			float: right;
		}
	}

	#menu-icon {
		top: 55px;
	}
}

.hero {
	color: #2c2c2c;
	margin: 200px 0 60px 0;
}

.hero-text {
	font-family: $heading-font-family;
	font-size: 36px;
	font-weight: 300;
	text-align: center;
}

.register-form {
	text-align: center;
	margin-top: 40px;
}

.register-form a {
	color: #fff;
	margin-top: 20px;
	display: block;
	text-shadow: 1px 1px 5px #000;
}

.register-form a:hover {
	text-decoration: underline;
}

input.home-form {
	width: 320px;
	height: 70px;
	border-radius: 3px;
	border: 10px solid rgba(255, 255, 255, 0.2);
	-webkit-background-clip: padding-box;
    background-clip: padding-box;
	padding: 20px;
	font-family: $body-font-family;
	font-size: 14px;
	margin: 0 5px;
}

.home-submit,
a.home-sumbmit {
	background: $color-blue;
	border-radius: 3px;
	width: 190px;
	height: 70px;
	text-align: center;
	color: #fff;
	border: none;
	font-family: $heading-font-family;
	font-size: 16px;
	transition: all 0.1s linear;

	&:hover {
		background: #25bbeb;
	}
}

.center {
	display: block !important;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}



/***** Main Body *****/


.mainbody {
	margin: 40px 0 0;
}

.home-contentarea {
	text-align: center;
}

.home-contentarea p {
	padding: 0 120px;
}

#contentarea,
.contentarea {
	background: url('../img/bg.png') repeat;
	padding: 40px 0;
	min-height: 650px;
}

.page-top {
	margin-bottom: 20px;
}

.page-title {
	margin-bottom: 10px;
}

.content-wrapper {
	background: #fff;
	padding: 20px;
	border: 1px solid $color-table-blue;
	border-radius: 3px;
	overflow: auto;
	margin-bottom: 30px;
}

.content-wrapper h3 {
	margin-top: 0;
}

ul.sidebar-list {
	list-style: none none;
	padding: 20px;
	background: $color-light-gray;
	border: 1px solid #e0e7ec;
	border-radius: 3px;
}

ul.sidebar-list li {
	margin-bottom: 8px;
}

ul.sidebar-list li:last-child {
	margin-bottom: 0;
}

ul.sidebar-list p {
	font-size: 11px;
	color: #5d656b;
	line-height: 14px;
	margin-top: 5px;
}

ul.activity li {
	margin-bottom: -10px;
	position: relative;
}

ul.activity li:last-child {
	margin-bottom: -20px;
}

.view-more {
	margin-top: 10px;
	margin-bottom: 0;
	font-size: 13px;
}

.industry-box {
	float: left;
	text-align: center;
	width: 160px;
	margin-right: 20px;
	margin-bottom: 20px;
}

.industry-box:nth-child(4) {
	margin-right: 0;
}

.industry-box-top {
	background: $color-light-gray;
	border: 1px solid #e0e7ec;
	padding: 20px;
}

.industry-box-bottom {
	background: #00a2e9;
	font-size: 12px;
	font-weight: 500;
	color: #fff;
	padding: 8px;
	margin-bottom: 10px;
}

.fee {
	overflow: auto;
	margin-bottom: 30px;
	font-size: 16px;
}

.fee-left {
	float: left;
	width: 75%;
	background: $color-light-gray;
	padding: 10px;
}

.fee-right {
	float: left;
	width: 25%;
	background: #00a2e9;
	font-weight: 700;
	color: #fff;
	padding: 10px;
	text-align: center;
}

.page-title h3 {
	margin-top: 0;
}

.update-wrapper {
	background: $color-dark-blue;
	color: #fff;
	padding: 10px 20px;
	border-radius: 5px;
	margin-bottom: 10px;
	font-size: 13px;
}

ul.status {
	list-style: none;
	padding-left: 0;
	margin-bottom: 30px;
}

ul.status li {
	margin-bottom: 10px;
}

ul.status img {
	margin-right: 10px;
	margin-top: -2px;
}

.complete {
	color: #8a959c;
}

.progressbar {
	background: #f5ddba;
	width: 100%;
	height: 16px;
	display: inline-block;
	vertical-align: middle;
	border-radius: 3px;
	margin: 10px 0 30px;
}

.progressbar-fill {
	height: 16px;
	width: 5%;
	display: block;
	border-radius: 3px;
	transition: width 1s, background-color 1s;
	background-color: $color-red;

	&.inprogress {
		background-color: $color-orange;
	}

	&.complete {
		background-color: $color-green;
	}
}

.progress-percent {
	font-weight: 700;
	color: $color-orange;
	background: #fff;
	float: right;
	font-size: 12px;
	position: relative;
	border-radius: 50%;
	border: 2px solid $color-orange;
	width: 40px;
	height: 40px;
	text-align: center;
	padding-top: 10px;
	margin: -12px -20px 0 0px;
}

#profile-completeness {
	li.complete {
		background: url(/img/icon-check.png) no-repeat left center;
		padding-left: 30px;
	}

	li.incomplete {
		background: url(/img/icon-alert.png) no-repeat left center;
		padding-left: 30px;
	}
}




/***** Table/Forms *****/


.styled-input {
	background: #fff;
	border: 1px solid $color-table-blue;
	height: 40px;
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.styled-drop {
	background: #fff;
	border: 1px solid $color-table-blue;
	border-radius: 0;
	height: 40px;
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.styled-textarea {
	width: 100%;
	background: $color-light-gray;
	border: 1px solid $color-table-blue;
	margin-top: 2px;
	margin-bottom: 10px;
	padding: 10px;
}

.table-drop {
	background: rgba(0, 0, 0, 0.1);
	border: none;
	width: 100%;
	height: 100%;
	min-width: 150px;
}

.no-padding {
	padding: 0 !important;
}

input.styled-radio {
	margin-right: 10px;
}

input.styled-checkbox {
	margin-right: 10px;
	margin-bottom: 20px;
	font-size: 18px;
}

input.submit {
	border: 0;
	border-radius: 3px;
	background: $color-faded-blue;
	color: #fff;
	padding: 10px 30px;
}

input.submit:hover {
	background: #576493;
}

input.file {
	position: absolute;
	top: 32px;
	left: 30px;
	font-size: 10px;
}

.styled-input-box {
	background: #fff;
	border: 1px solid $color-table-blue;
	height: 40px;
	width: 100%;
	padding: 10px;
	margin-bottom: 10px;
	margin-right: 10px;
}

.form-label {
	display: block;
	font-size: 11px;
	font-weight: bold;
	margin-bottom: 5px;
}

.required {
	color: red;
}

.calendar {
	position: absolute;
	top: 0px;
	left: 0px;
	background: $color-light-gray;
	padding: 9px;
	border: 1px solid $color-table-blue;
}

.styled-table, .lrg-table {
	border-collapse: collapse;
	border-spacing: 0;
	border: none;
	width: 100%;
	margin-bottom: 40px;
}

.styled-table tr, .lrg-table tr {
	border-top: 1px solid #fff;
}

.styled-table th, .lrg-table th {
	padding: 10px 20px;
	overflow: hidden;
	word-break: normal;
	background: $color-med-gray;
	color: #fff;
}

.styled-table td {
	padding: 10px 20px;
	overflow: hidden;
	word-break: normal;
	background: #ebebeb;
}

.lrg-table td {
	padding: 20px;
	overflow: hidden;
	word-break: normal;
	font-size: 12px;
}

.lrg-table tr:nth-child(even) {
	background: $color-table-blue;
}

.lrg-table tr:nth-child(odd) {
	background: $color-light-gray;
}

.form-field {
	margin-bottom: 10px;
	margin-right: 2%;
	float: left;
	width: 48%;
	position: relative;

	&.full-width {
		width: 98%;
	}
}

a.search-icon {
	background: url('../img/icon-search.png') no-repeat;
	width: 12px;
	height: 12px;
	display: block;
	float: right;
	position: relative;
	top: -35px;
	right: 10px;
}

ul.pagination {
	display: table;
	margin: 0 auto 20px auto;
	font-size: 12px;
}

.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
	background-color: #00a2e9;
	border-color: #00a2e9;
}

tr.past-due {
	background: $color-light-red !important;
	font-weight: 500;
}

tr.due-soon {
	background: $color-light-orange !important;
	font-weight: 500;
}



/***** Footer *****/


.footer {
	background: $color-med-gray;
	color: #fff;
	font-size: 13px;
	padding: 40px 0 20px 0;
	text-align: center;
}

.footer-nav ul {
	list-style: none none;
	margin: 0 0 20px 0;
	padding: 0;
}

.footer-nav ul li {
	display: inline;
	margin: 0 10px;
}

.footer-nav ul li a {
	color: #fff;
}

.footer-nav ul li a:hover {
	color: #b22020;
	text-decoration: none;
}

.footer-nav p {
	font-size: 13px;
	color: #fff;
	margin-bottom: 20px;
}

.clear {
	clear: both;
}



/*********** Payment *****************/

.cvv-wrap {
	width: 10%;
}

.expiration-wrap {
	width: 36%;

	select {
		float: left;
		clear: both;
		width: calc(50% - 10px);

		+ select {
			clear: none;
		}
	}
}

.plan-table {
	width: 75%;
	margin: 10px auto;

	tr {
		td {
			padding: 10px;
		}
		td:nth-child(2),
		td:nth-child(3),
		th:nth-child(2),
		th:nth-child(3) {
			text-align: center;
		}
	}
}

.pricing-table {
	width: 75%;
	margin: 10px auto;

	tr {
		td {
			padding: 10px;
		}

		td:nth-child(2),
		td:nth-child(3),
		th:nth-child(2),
		th:nth-child(3) {
			text-align: center;
		}
	}

	tr.light-row {
		background-color: $color-light-gray;	
		border:none;
	}
	
	tr.dark-row {
		background-color: $color-table-blue;	
		border:none;
	}
}

.terms {
	width: 60%;
	height: 200px;
	border: 1px solid black;
	overflow-y: scroll;
	padding: 3px;
	margin: 10px auto;
	
	h5, h6 {
		font-size: 1.5em;
	}
}


/********** Company Registration *****/
.company-state-extra-form {
	border: 1px solid $color-blue-gray;
	background: $color-light-gray;
	margin: 3px;
	padding: 5px 15px;

	.form-field {
		float: none;
		width: auto;
	}
}

#license-forms,.company-state-extra-form {
	.form-field {
		&.disabled {
			color: #999;

			&:after {
				content: 'N/A';
				color: #f00;
				padding-left: 10px;
				font-size: .8em;
			}
		}
	}
}


.clear-filters {
	cursor: pointer;
}

.contingent {
	display: none;
}

.validation-error {
	color: red;
}


/*********** Media Queries ***********/


@media (max-width: 1199px) {

	.scholar-info td {
		padding: 10px 20px;
	}

	.industry-box {
		width: 275px;
		margin-bottom: 10px;
	}

	.industry-box-top {
		padding: 50px;
	}

	.industry-box-bottom {
		font-size: 13px;
		padding: 14px;
	}

	.industry-box:nth-child(2) {
		margin-right: 0;
	}


}

@media (max-width: 991px) {

	.content-wrapper {
		margin-bottom: 20px;
	}

	.home-brand {
		display: inline-block;
	}

	.hero {
		margin-top: 160px;
	}

	input.home-form {
		width: 260px;
	}

	input.home-submit {
		width: 170px;
	}


}


@media (max-width: 767px) {

	.hero-text {
		font-size: 40px;
		line-height: 48px;
	}

	input.home-form {
		width: 80%;
		margin-bottom: 10px;
	}

	input.home-submit {
		width: 40%;
	}

	.hero {
		margin-top: 100px;
	}

	.form-field {
		margin-bottom: 10px;
		margin-right: 0;
		float: none;
		width: 100%;
		position: relative;
		display: block;
	}

	.industry-box {
		width: 100%;
	}

	.field-reset {
		width: 100% !important;
	}



}



@media (max-width: 480px) {

	.hero {
		margin-top: 90px;
		margin-bottom: 40px;
	}

	.hero-text {
		font-size: 32px;
		line-height: 40px;
	}

	#home-contentarea p {
		padding: 0 10px;
	}


}
