/** Structure */
#wrapper {
  @include transition(transform, $transition-speed);

  &:before {
    content: '';
    display: block;
    clear: both;
    width: 1px;
    height: 1px;
  }
}

#menu-icon {
  display: none;
}

/***** Drop Down Menu *****/

.nav ul {
  ul {
    display: none;
  }

  li {

    &:hover > ul {
      display: block;

      li {
        display: block;

        &:hover > ul {
          li:hover {
            background: #1da2cd;
          }
        }

        a:hover {
          color: #fff;
          transition: none;
        }
      }
    }

    > ul {
      position: absolute;
      top: 20px;
      left: 0;
      z-index: 9999;
      background: #e6ecf0;
      margin-top: 29px;
      margin-left: -19px;
      padding: 0;

      li {
        float: none;
        margin: 0;
        min-width: 200px;
        white-space: nowrap;
        padding: 8px 5px;
        font-size: 12px;
        border-left: 0;

        a {
          color: #111;
          padding: 10px 15px;
          transition: none;
          text-decoration: none;
        }
      }
    }
  }

  .open > a, .open >a:focus, .open > a:hover {
	background-color: rgba(0, 0, 0, 0);
  }
}


.nav-main {
  color: #fff;
  float: left;
  padding-top: 10px;

  ul {
    list-style: none none;
    font-size: 13px;
    list-style: none none;
    margin: 0 0 0 20px;

    ul {
      display: none;
    }

    li {
      display: inline;
      position: relative;
      padding: 20px 0;
      margin-right: 30px;

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        > ul {
          display: block;
          padding: 0;
          background: $color-light-gray;
          li {
            a {
              color: $color-dark-gray;
              padding: 10px 15px;
              transition: none;
            }

            &:hover {
              background: $color-blue;
            }

            a:hover {
              color: #fff;
              transition: none;
            }
          }
        }
      }

      ul {
        position: absolute;
        top: 20px;
        left: 0;
        z-index: 9999;
      }

      a {
        color: #fff;

        &, .nav-aux ul li a {
          color: #000;

          &:hover {
            text-decoration: underline;
          }
        }

        &:hover {
          color: $color-blue;
          text-decoration: none;
        }

        img {
          margin-top: -4px;
          margin-right: 10px;
        }
      }
    }
  }

  .avatar {
    width: 30px;
    height: 30px;
    border-radius: 50%
  }
}

.nav-aux {
  margin-top: 8px;
  color: #000;
  float: right;
  display: inline-block;

  ul {
    font-size: 14px;
    list-style: none none;
    margin: 0;
    float: right;

    li {
      margin-left: 10px;
      display: inline;
      position: relative;
      padding: 20px 0;
    }
  }
}

.util-nav ul {
  background: #f0f4f7;
  list-style: none none;
  padding: 4px 15px;
  margin-bottom: 0;
  text-align: center;
  float: right;
  border-left: 1px solid $color-table-blue;
  border-right: 1px solid $color-table-blue;
  font-weight: 700;

  li {
    display: inline-block;
    line-height: 30px;
    margin-right: 15px;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media (max-width: 1199px) {

  .nav ul li {
    margin-right: 15px;
  }

}

@media (max-width: 767px) {
  
  #menu-icon {
    background: url('../img/icon-menu.png') no-repeat;
    width: 26px;
    height: 24px;
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 39px;
  }

  html.csstransforms3d {
    #wrapper {
      @include transform(translate3d(0, 0, 0));
    }

    &.nav-open #wrapper {
      @include transform(translate3d($off-canvas-nav-width, 0, 0));
    }
  }

  html.no-csstransforms3d {
      #wrapper {
        position: relative;
        left: 0;
      }
      &.nav-open #wrapper {
        left: $off-canvas-nav-width;
      }
  }

  .nav {
      position: absolute;
      left: -1 * $off-canvas-nav-width;
      top: 0;
      width: $off-canvas-nav-width;
      min-height: 100%;
      padding: 2em 1.5em;
      background: #000;
      margin-top: 0;
  }

  .nav {
    background: #000;
    border-right: 1px solid #fff;
  }

  #primary-nav {

    ul {
      float: none;
      margin: 0;
      padding: 0;

      ul {
        width: auto;
        display: block;
        position: relative;
        top: 0;
        left: 0;
        border-width: 0;
        max-height: 0;
        overflow: hidden;
        background: none;
        border-top: none;
        transition: max-height .5s, border-width .5s;
        margin: 5px 0;

        li {
            a {
                color: #fff;
            }
        }

        ul {
            position: relative;
            top: 10px;
            left: 20px;
        }
      }

      li {
        display: block;
        padding: 8px 0;
        margin-bottom: 5px;
        margin-left: 0;
        min-height: 39px;

        a {
          color: #fff;

          &:hover {
            color: #fff;
          }
        }

        &.open {
          ul {
            max-height: 300px;
            border-width: 2px 0;
          }

          a,
          a:hover {
            background-color: transparent;
            border: none;
          }
        }
      }
    }
  }

  .nav .util-nav ul {
    float: none;
    padding: 0;
    background: none;
    border: none;
    text-align: left;

    img {
      background: #f0f4f7;
    }
  }

  #menu-icon {
    margin-right: 10px;
  }

  .nav {
    margin-top: 0 !important;
  }

}
